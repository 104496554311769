import React, { useState } from 'react';
import RightIcon from "../assets/Icons/rightIcon.png";
import LeftIcon from "../assets/Icons/leftIcon.png";

const Carousel = ({ images }) => {
    const [currentImage, setCurrentImage] = useState(0);
    const totalImages = images.length;

    const nextImage = () => {
        setCurrentImage((currentImage + 1) % totalImages);
    };
    
    const previousImage = () => {
        setCurrentImage((currentImage - 1 + totalImages) % totalImages);
    };
    
    const arrowStyle = 'absolute text-white text-2xl z-10 h-10 w-10 rounded-full flex items-center justify-center';
    
    return (
        <div className="flex justify-center w-full items-center relative">
            <button
                type="button"
                onClick={previousImage}
                className={`${arrowStyle} left-2`}
                style={{ top: '40%' }}
            >
                <img src={LeftIcon} alt="Left Arrow" />
            </button>
            <div className="w-full">
                <img src={images[currentImage]} className="w-full object-contain" alt={`Image ${currentImage}`} />
            </div>
            <button
                type="button"
                onClick={nextImage}
                className={`${arrowStyle} right-2`}
                style={{ top: '40%' }}
            >
                <img src={RightIcon} alt="Right Arrow" />
            </button>
        </div>
    );
};

export default Carousel;
