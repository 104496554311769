import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import headerBG from "../assets/Backgrounds/communityHeaderBackground.png";

const HeroCollegeDistrict = ({isCommunityPage}) => {

	const [increaseHeight, setIncreaseHeight] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


	const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

	const headerStyle = {
        backgroundImage: `url(${headerBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        width: '100%',
        height: isMobile && increaseHeight ? '870px' : '724px', 
    };

	return (
		<header style={headerStyle} >
			<div className="h-full flex justify-center">
				<div className="w-full pr-[8%] pl-[8%] h-full flex flex-col justify-between items-center py-[77px]">
					<Navbar isCommunityPage={isCommunityPage} setIncreaseHeight={setIncreaseHeight} increaseHeight={increaseHeight}/>
					<div className="w-full flex flex-row justify-between">
						<div>
							<h1 className="text-white font-['Oswald'] font-bold text-[1.8rem] lg:text-[4.25rem] 2xl:text-[4rem] uppercase">
								STATE CENTER COMMUNITY COLLEGE DISTRICT
							</h1>
							<a href="https://www.scccd.edu/about/index.html" className="text-white font-bold text-[1.25rem] lg:text-[2rem] font-['IstokWeb']">About</a>
							<p className="text-white font-['IstokWeb'] font-normal text-[1rem] lg:text-[1.4rem] box-border pt-[30px]">
								State Center Community College District (SCCCD) was formed in 1964 and serves approximately 1.7 million people and 38 
								
								unified and high school districts in more than 5,743 square miles of urban and rural territory, including most of Fresno and 
								
								Madera counties and portions of Kings and Tulare counties.
							</p>
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default HeroCollegeDistrict;
