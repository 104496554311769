import SectionHeader from "./SectionHeader";
import ParticipatoryImg from "../assets/Participatory/participatoryImg.png";

const Participatory = () => {
    return (
        <div>
            <SectionHeader title="The Planning Process" subtitle="Participatory" backTo="Back to Planning Process" backToLink="/planning-process" goTo="Meet the Planning Teams" goToLink="/planning-process/participatory/team"/>

            <div className="flex flex-col lg:flex-row items-start mt-[21px] lg:space-x-20">
                <p className="text-[1.6rem] font-bold font-[IstokWeb] text-white w-full lg:w-[20.25rem]">
                    This long-term plan was developed in collaboration with the Chancellor’s Vision 2035 Commission as 
                    well as a variety of districtwide engagement activities.
                </p>
                <div className="w-full lg:w-[56.5rem]">
                    <img className="" src={ParticipatoryImg} />
                </div>
            </div>
        </div>
    );
};

export default Participatory;
