import React, { useState, useEffect } from 'react';
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BentoBox from '../components/BentoBox';
import Hero from '../components/Hero';
import Colleges from '../components/CollegesSection';
import SimplePopUp from '../components/SimplePopUp';
import Carousel from '../components/Carousel';
import { useLocation } from 'react-router-dom';

const College = ({}) => {

    const [showPopUp, setShowPopUp] = useState(false);
    const { pathname } = useLocation();

    const showPopUpCallback = () => {
      setShowPopUp(!showPopUp);
    }

    const layout = [
        { col: 0, row: 0, colSpan: 1, rowSpan: 1 }, 
        { col: 1, row: 0, colSpan: 3, rowSpan: 1 }, 
        { col: 0, row: 2, colSpan: 2, rowSpan: 1 }, 
        { col: 0, row: 3, colSpan: 2, rowSpan: 1 },  
        { col: 2, row: 1, colSpan: 1, rowSpan: 3 } 
    ];
    
    const layout2 = [
        { col: 0, row: 0, colSpan: 1, rowSpan: 3 }, 
        { col: 1, row: 0, colSpan: 3, rowSpan: 1 },
        { col: 1, row: 1, colSpan: 2, rowSpan: 1 }, 
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <div>
            <header className="colleges-header-bg">
				<div className="h-full flex justify-center">
					<div className="w-full pr-[8%] pl-[8%] h-full flex flex-col justify-between items-center py-[77px]">
                        <Navbar isCommunityPage={true}/>
						<div className="w-full flex flex-row justify-between">
							<div>
								<h1 className="text-white font-['Oswald'] font-bold text-[3.25rem] lg:text-[4.25rem] 2xl:text-[6.25rem] uppercase">State Center Colleges</h1>
								<p className="text-white font-['IstokWeb'] font-normal text-[1rem] md:text-[1.875rem] box-border pt-[30px]">
                                    Space for a short introduction text about a brief statement
								</p>
							</div>
						</div>
                        <div className="w-full my-12">
                            <button className="bg-[#143057] text-white text-[1rem] font-[IstokWeb] font-normal font-bold rounded-md px-4 py-1">
                                Learn more about the vision
                            </button>
                        </div>
                        <div className="w-full">
                            <Colleges isCollegePage={true}/>
                        </div>
					</div>
				</div>
			</header>
            <Footer />
        </div>
  );
};

export default College;
