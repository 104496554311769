import React, { useState, useEffect } from 'react';
import North from "../assets/Icons/north.png";
import South from "../assets/Icons/south.png";

const ImageSelector = ({ data, color, buttonsInMiddle,isSatelliteCampus }) => {
    const [selectedButton, setSelectedButton] = useState(data[0]);
    const [selectedBuildingButton, setSelectedBuildingButton] = useState(data[0].buildings);

    useEffect(() => {
        if(selectedButton.text === "Future reconstruction"){
            setSelectedBuildingButton(selectedButton.buildings[0]);
        }
    },[selectedButton])

    const handleButtonClick = (index) => {
        setSelectedButton(data[index]);
        setSelectedBuildingButton(null);
    };

    const handleBuildingButtonClick = (index) => {
        setSelectedBuildingButton(selectedButton.buildings[index]);

        const section = document.getElementById('buildingSection');
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const buttonSelectedStyle = {
        backgroundColor: color
    };

    const buttonStyle = {
        backgroundColor: 'white', 
        borderColor: color, 
        borderWidth: '2px'
    };

    const labelSelectedStyle = {
        color: 'white'
    };

    const labelStyle = {
        color: color, 
        borderColor: color
    };


    return (
        <div>
            <div className="flex flex-col md:flex-col items-start mt-[8px]">
                {!buttonsInMiddle && <>
                    <div className="flex flex-col md:flex-row w-full md:space-x-6">
                        {data.map((button, index) => (
                            button.text && 
                                <button
                                    key={index}
                                    onClick={() => {
                                        handleButtonClick(index);
                                    }}
                                    style={
                                        color ? 
                                            (selectedButton === data[index] ? buttonSelectedStyle : buttonStyle) 
                                        :
                                            (selectedButton === data[index] ? {backgroundColor: 'white', borderColor: button.color, borderWidth: '2px'} : {backgroundColor: button.color}) 
                                            
                                    }
                                    className={`px-4 rounded-full mb-4 md:mb-10`}
                                >
                                    <div className="flex items-center">
                                        <label htmlFor={button.text} 
                                            className={`w-full py-2 font-['Oswald'] font-semibold text-[1.152rem] text-start uppercase`}
                                            style={
                                                color ? 
                                                    (selectedButton === data[index] ? labelSelectedStyle  : labelStyle) 
                                                :
                                                    (selectedButton === data[index] ? {color: button.color, borderColor: button.color} : {color: 'white'}) 
                                                    
                                            }
                                        >
                                            {button.text}
                                        </label>
                                    </div>
                                </button>
                        ))}
                    </div>
                    <div className="mb-4">
                        <h3 className="text-[1.125rem] font-['IstokWeb'] font-bold text-[#143057]">{selectedButton.description}</h3>
                    </div>
                    <div className="grid grid-cols-1 flex-wrap justify-items-center w-full md:flex md:flex-row md:space-x-6">
                        {selectedButton?.buildings?.map((button, index) => (
                            <button
                                key={index}
                                onClick={() => {
                                    handleBuildingButtonClick(index);
                                }}
                                style={
                                    color ? 
                                        (selectedBuildingButton === data[index] ? buttonSelectedStyle : buttonStyle) 
                                    :
                                        (selectedBuildingButton === selectedButton.buildings[index] ? {backgroundColor: 'white', borderColor: selectedButton.color, borderWidth: '2px'} : {backgroundColor: selectedButton.color}) 
                                }
                                className={`px-4 rounded-full mb-4 min-w-[200px] md:min-w-[100px]`}
                            >
                                <div className="flex items-center">
                                    <label htmlFor={button.text} 
                                        className={`w-full py-2 font-['Oswald'] font-semibold text-[0.8rem] md:text-[1.152rem] text-center uppercase`}
                                        style={
                                            color ? 
                                                (selectedBuildingButton === data[index] ? labelSelectedStyle  : labelStyle) 
                                            :
                                                (selectedBuildingButton === selectedButton.buildings[index] ? {color: selectedButton.color, borderColor: selectedButton.color} : {color: 'white'}) 
                                                
                                        }
                                    >
                                        {button.text}
                                    </label>
                                </div>
                            </button>
                        ))}
                    </div>
                </>}

                <div className="relative">
                    <img src={selectedButton.img} alt="Selected" className="max-w-full max-h-full" />
                    {selectedButton.direction && 
                        <img src={selectedButton.direction === "North" ? North : South} alt="Icon" className="absolute bottom-0 left-0 mb-6 ml-6 w-6 h-6" />
                    }
                </div>

               {/* <div className="">
                    <img src={selectedButton.img} alt="Selected" className="max-w-full max-h-full" />
                </div>
                    */}
                {buttonsInMiddle && !isSatelliteCampus && <>
                    <h1 id="buildingSection" className="mt-[42px] uppercase text-[#143057] text-[1.5rem] lg:text-[2rem] font-['Oswald'] font-bold">PROJECT DESCRIPTIONS</h1>
                    <div className="flex flex-col md:flex-row w-full md:space-x-6 mt-[21px]">
                        {data.map((button, index) => (
                            button.text && 
                                <button
                                    key={index}
                                    onClick={() => {
                                        handleButtonClick(index);
                                    }}
                                    style={
                                        color ? 
                                            (selectedButton === data[index] ? buttonSelectedStyle : buttonStyle) 
                                        :
                                            (selectedButton === data[index] ? {backgroundColor: 'white', borderColor: button.color, borderWidth: '2px'} : {backgroundColor: button.color}) 
                                            
                                    }
                                    className={`px-4 rounded-full mb-4 md:mb-10`}
                                >
                                    <div className="flex items-center">
                                        <label htmlFor={button.text} 
                                            className={`w-full py-2 font-['Oswald'] font-semibold text-[1.152rem] text-start uppercase`}
                                            style={
                                                color ? 
                                                    (selectedButton === data[index] ? labelSelectedStyle  : labelStyle) 
                                                :
                                                    (selectedButton === data[index] ? {color: button.color, borderColor: button.color} : {color: 'white'}) 
                                                    
                                            }
                                        >
                                            {button.text}
                                        </label>
                                    </div>
                                </button>
                        ))}
                    </div>

                    <div className="grid grid-cols-1 flex-wrap justify-items-center w-full md:flex md:flex-row md:space-x-6">
                        {selectedButton?.buildings?.map((button, index) => (
                            button.text &&
                                <button
                                    key={index}
                                    onClick={() => {
                                        handleBuildingButtonClick(index);
                                    }}
                                    style={
                                        color ? 
                                            (selectedBuildingButton === data[index] ? buttonSelectedStyle : buttonStyle) 
                                        :
                                            (selectedBuildingButton === selectedButton.buildings[index] ? {backgroundColor: 'white', borderColor: selectedButton.color, borderWidth: '2px'} : {backgroundColor: selectedButton.color}) 
                                    }
                                    className={`px-4 rounded-full mb-4 min-w-[200px] md:min-w-[100px]`}
                                >
                                    <div className="flex items-center">
                                        <label htmlFor={button.text} 
                                            className={`w-full py-2 font-['Oswald'] font-semibold text-[0.8rem] md:text-[1.152rem] text-center uppercase`}
                                            style={
                                                color ? 
                                                    (selectedBuildingButton === data[index] ? labelSelectedStyle  : labelStyle) 
                                                :
                                                    (selectedBuildingButton === selectedButton.buildings[index] ? {color: selectedButton.color, borderColor: selectedButton.color} : {color: 'white'}) 
                                                    
                                            }
                                        >
                                            {button.text}
                                        </label>
                                    </div>
                                </button>
                        ))}
                    </div>
                </>}
                {selectedBuildingButton &&
                    <div className="mt-[21px]">
                        <div className={`${selectedBuildingButton.plan ? 'h-full' : 'h-full md:h-[30vh]'} flex flex-col md:flex-row w-full`}>
                            {selectedBuildingButton.plan &&
                                <div className="md:w-[29vw] md:min-w-[29vw] max-w-[84vw] max-h-[40vh]">
                                    <img src={selectedBuildingButton.plan} alt="Selected" className="object-cover max-w-full max-h-full" />
                                </div>
                            }

                            {selectedBuildingButton?.list &&
                                <div className="flex flex-col md:w-[29vw] md:min-w-[29vw]">
                                    {selectedBuildingButton?.list?.map((button, index) => (
                                        <p className={`${selectedBuildingButton && 'px-4'} font-[IstokWeb] font-normal text-[0.7rem] text-[#143057]`} >
                                            {button}
                                        </p>
                                    ))}
                                </div>
                            }
                            {selectedBuildingButton?.description &&
                                <div className={`flex flex-col md:w-[20%] w-full} `}>
                                    {selectedBuildingButton?.description?.map((button, index) => (
                                        <p className={`${selectedBuildingButton && 'px-4'} font-[IstokWeb] font-normal text-[#143057] description-text-size`} >
                                            {button}
                                        </p>
                                    ))}
                                </div>
                            }
                            {selectedBuildingButton.render && 
                                <div className="md:w-[45%] h-full">
                                        <img src={selectedBuildingButton.render} alt="Selected" className="max-w-full max-h-full h-full w-full" />
                                    
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ImageSelector;
