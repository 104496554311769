import React, { useState } from 'react';
import PlusIcon from "../assets/Icons/plusIcon.png";
import { useNavigate   } from 'react-router-dom';

const Card = ({key, img, title, isCollegePage, textPosition, link, color}) => {
    const [isHovered, setIsHovered] = useState(false);
    let navigate = useNavigate();


    const redirect = (link) => {
		//navigate(link, { replace: true });
        window.location.href = link;
	}
    return(
        <div id={key} className={`${textPosition === 'overlay' ? 'relative w-full lg:w-[490.66px]' : ' max-w-sm w-[245.33px]'} overflow-hidden cursor-pointer`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={() => redirect(link)}
            tabIndex={0}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    redirect(link);
                }
            }}
        >
            {textPosition && 
                <div className={`${isHovered ? 'opacity-0' : 'opacity-60' } transition-filter duration-1000 absolute bottom-0 left-0 right-0 top-0 w-full overflow-hidden bg-fixed rounded-lg`}
                    style={{backgroundColor: color}}
                >

                </div>
            }

            <img className={`${textPosition && 'rounded-lg'} h-[246.3px] bg-cover w-full`} src={img} alt="Card Image"/>
            <div className={`${textPosition === 'overlay' && 'absolute inset-0 flex items-center justify-center px-12'} py-[22px]`}>
                {textPosition && <img src={PlusIcon} className={`w-[40px] h-[40px] mr-6`}/> }
                <div className={`${isCollegePage ? 'text-white' : 'text-[#143057]'} font-['IstokWeb'] font-bold text-[1.5rem] ${textPosition === 'overlay' ? 'text-start' : 'text-center'}`}>
                    {title}
                </div>
            </div>
            
        </div>
    );
};

export default Card;