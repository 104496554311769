import React, { useState } from 'react';
import DefaultMap from "../assets/Maps/defaultMap.png";
import FresnoMap from "../assets/Maps/fresnoMap.png";
import ReedleyMap from "../assets/Maps/reedleyMap.png";
import ClovisMap from "../assets/Maps/clovisMap.png";
import MaderaMap from "../assets/Maps/maderaMap.png";
import AllMap from "../assets/Maps/allMap.png";
import SectionHeader from './SectionHeader';

const DistrictMap = () => {
    const [selectedImage, setSelectedImage] = useState(AllMap);
    const [selectedButton, setSelectedButton] = useState(null);

    const buttons = [
        { label: 'Fresno College', id: 'fresno-radio', image: FresnoMap, color: '#88181D' },
        { label: 'Reedley College', id: 'reedley-radio', image: ReedleyMap, color: '#FE852D' },
        { label: 'Clovis Community College', id: 'clovis-radio', image: ClovisMap, color: '#00AEDA' },
        { label: 'Madera Community College', id: 'madera-radio', image: MaderaMap, color: '#B78F26' },
        { label: 'District Office', id: 'offices-radio', image: DefaultMap, color: '#008FD1' },

    ]

    const handleButtonClick = (imageName) => {
        setSelectedImage(imageName);
    };

    const handleButtonSelect = (buttonName) => {
        setSelectedButton(buttonName);
    };

    return (
        <div>
            <SectionHeader title="Districtwide Map"/>

            <div className="flex flex-col md:flex-row items-start mt-[21px]">
                <div className="flex flex-col mr-10 w-[325px] min-w-[325px]">
                    {buttons.map((button, index) => (
                        <button
                            key={index}
                            onClick={() => {
                                handleButtonClick(button.image);
                                handleButtonSelect(button.id);
                            }}
                            style={selectedButton === button.id ? {backgroundColor: `${button.color}`} : {backgroundColor: 'white'}}
                            className={`border px-4 xl:py-2 rounded-md mb-2 ${selectedButton === button.id ? `border-white` : 'border-[#008FD1]'}`}
                        >
                            <div className="flex items-center ps-3">
                                <label htmlFor={button.id} className={`w-full py-3 font-['IstokWeb'] font-bold text-[1.152rem] text-start  ${selectedButton === button.id ? `text-white` : 'text-[#143057]'}`}>
                                    {button.label}
                                </label>
                                <input
                                    id={button.id}
                                    type="radio"
                                    value=""
                                    name="list-radio"
                                    className={`accent-[${button.color}] w-[26.5px] h-[26.5px] text-blue-600 border-gray-300 focus:ring-[${button.color}] focus:ring-2`}
                                />
                            </div>
                        </button>
                    ))}
                </div>

                <div className="">
                    <img src={selectedImage} alt="Selected" className="max-w-full max-h-full" />
                </div>
            </div>
        </div>
    );
};

export default DistrictMap;
