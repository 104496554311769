import BackButtonIcon from "../assets/Icons/backArrowIcon.png";
import GoButtonIcon from "../assets/Icons/goArrowIcon.png";
import BackBlueButtonIcon from "../assets/Icons/backBlueArrowIcon.png";

const SectionHeader = ({ title, subtitle, backTo, backToLink, goTo, goToLink, page, blueText }) => {
    return (
        <div className="flex flex-col lg:flex-row justify-between items-center">
            <h2 className={`self-start text-[3rem] lg:text-[3.5rem] font-['Oswald'] font-bold ${blueText ? 'text-[#143057]' : 'text-[#00AEDA]'} uppercase`}>{title} <span className="text-white">{subtitle}</span></h2>
            <div className="z-50 self-end">
                <a href={`${backTo ? backToLink : "/"}`} className="flex items-center space-x-2 cursor-pointer">
                    <img src={blueText ? BackBlueButtonIcon : BackButtonIcon} className="w-[25.55px]"/>
                    <span className={`${blueText ? 'text-[#143057]' : 'text-white'}  font-['IstokWeb'] text-[1.563rem]`}>{backTo ? backTo : "Back"}</span>
                </a>
                {goTo &&
                    <a href={`${goTo ? goToLink : "/"}`} className={`${page === "team" && 'flex-row-reverse'} flex items-center cursor-pointer`}>
                        <span className="text-white font-['IstokWeb'] text-[1.563rem]"> {goTo}</span>
                        <img src={page === "team" ? BackButtonIcon : GoButtonIcon} className="w-[25.55px] mx-2"/>
                    </a>
                }
            </div>
            
        </div>
      
    );
};

export default SectionHeader;
