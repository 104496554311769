import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import LandingPage from './pages/LandingPage';
import College from './pages/College';
import Integrated from './components/Integrated';
import Participatory from './components/Participatory';
import Team from './components/Team';
import DataInformed from './components/DataInformed';
import CommunityCollegeDistrict from './pages/CommunityCollegeDistrict';
import PlanningProcessSteps from './components/PlanningProcessSteps';
import CommunityCollegeContent from './components/CommunityCollegeContent';
import CollegeTemplate from "./components/CollegeTemplate";
import CollegesData from "./data/collegesData.json";

function App() {


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate to="/home" />}></Route>
                <Route path="/:PanelType" element={<LandingPage />}>
                    <Route path=":PlanningProcessSection" element={<Integrated />} />
                    <Route path=":PlanningProcessSection" element={<Participatory />}>
                        <Route path=":ParticipatoryTeam" element={<Team />} />
                    </Route>
                    <Route path=":PlanningProcessSection" element={<DataInformed />} />
                    <Route path=":PlanningProcessSection" element={<PlanningProcessSteps />} />
                </Route>
				<Route path="/colleges" element={<College />}></Route>
                <Route path="/colleges/:College" element={<CollegeTemplate collegeData={CollegesData.colleges[0]}/>}>
                    <Route path=":CollegeSection" element={<CollegeTemplate collegeData={CollegesData.colleges[0]}/>}></Route>
                </Route>
                {/*<Route path="/colleges/reedley" element={<CollegeTemplate collegeData={CollegesData.colleges[1]}/>}></Route>
                <Route path="/colleges/clovis" element={<CollegeTemplate collegeData={CollegesData.colleges[2]}/>}></Route>
    <Route path="/colleges/madera" element={<CollegeTemplate collegeData={CollegesData.colleges[3]}/>}></Route>*/}
                <Route path="/community-college-district" element={<CommunityCollegeDistrict />}>
                    <Route path=":CommunitySection" element={<CommunityCollegeContent />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
