import React from 'react';
import StateCenterLogo from "../assets/Logos/stateCenterSmallLogo.png";

const Footer = () => {
    const logos = [StateCenterLogo,StateCenterLogo,StateCenterLogo];

    return (
        <div  className="py-[32px] bg-[#143057] flex justify-center">
            <div className="w-full px-[8%]">
                <div className="py-8">
                    <div className="grid grid-cols-1 md:grid-cols-3 flex justify-between items-start">
                        <div>
                            <img src={StateCenterLogo} className='w-[80px] h-[77px] mb-8' alt="State Center Logo"></img>

                            <div className="pb-4 font-normal text-[1rem] font-['IstokWeb'] text-white">
                                <h1>1171 Fulton Street</h1>
                                <h1>Fresno CA 93721</h1>
                                <h1>Phone: (559) 243-7100</h1>
                            </div>
                        </div>
                        
                        <div className="flex h-[100%] pb-4 justify-end">
                            <div className="pb-4 text-white text-right">
                                <a href="/" className="pb-4 font-bold text-[1rem] font-['IstokWeb']">Home</a>
                                <div className="flex flex-col cursor-pointer font-normal text-[1rem] font-['IstokWeb']">
                                    <a href="/">State Center Vision 2035</a>
                                    <a>How was the vision developed?</a>
                                    <a href="/district-wide-map">Districtwide Map</a>
                                </div>
                            </div>
                        </div>
                        
                        <div className="flex h-[100%] pb-4 justify-end">
                            <div className="pb-4 text-white text-right">
                                <a href="/colleges" className="pb-4 font-bold text-[1rem] font-['IstokWeb']">District’s Colleges</a>
                                <div className="flex flex-col cursor-pointer font-normal text-[1rem] font-['IstokWeb']">
                                    <a href="/colleges/fresno">Fresno City College</a>
                                    <a href="/colleges/reedley">Reedley College</a>
                                    <a href="/colleges/clovis">Clovis Community College</a>
                                    <a href="/colleges/madera">Madera Community College</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className="pt-4">
                </div>
            </div>
        </div>
    );
};

export default Footer;
