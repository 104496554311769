import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import headerBG from "../assets/Backgrounds/headerBackground.png";

	const Hero = () => {

		const [increaseHeight, setIncreaseHeight] = useState(false);
		const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


		const handleResize = () => {
			setIsMobile(window.innerWidth <= 768);
		};
	
		useEffect(() => {
			window.addEventListener('resize', handleResize);
			return () => {
				window.removeEventListener('resize', handleResize);
			};
		}, []);
	
		const headerStyle = {
			backgroundImage: `url(${headerBG})`,
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			width: '100%',
			height: isMobile && increaseHeight ? '810px' : '645px', 
		};

		

		return (
			<header style={headerStyle} >
				<div className="h-full flex justify-center">
					<div className="w-full pr-[8%] pl-[8%] h-full flex flex-col justify-between items-center py-[77px]">
						<Navbar setIncreaseHeight={setIncreaseHeight} increaseHeight={increaseHeight} />
						<div className="w-full flex flex-row justify-between">
							<div>
								<h1 className="text-white font-['Oswald'] font-bold text-[3.25rem] lg:text-[4.25rem] 2xl:text-[6.25rem] uppercase">State Center Vision 2035</h1>
								<p className="text-white font-['IstokWeb'] font-normal text-[1rem] md:text-[1.875rem] box-border pt-[30px]">
									Vision 2035 is our shared vision and strategic blueprint, driving efforts to evolve <br/> as a 
									life-changing asset for our students and communities.
								</p>
							</div>
						</div>
					</div>
				</div>
			</header>
	  );
};

export default Hero;
