import SectionHeader from "./SectionHeader";
import IntegratedCircles from "../assets/Integrated/integratedCircles.png";
import IntegratedArrow from "../assets/Integrated/integratedArrow.png";

const Integrated = () => {
    return (
        <div>
            <SectionHeader title="The Planning Process" subtitle="Integrated" backTo="Back to Planning Process" backToLink="/planning-process"/>

            <div className="flex flex-col lg:flex-row items-start mt-[21px] lg:space-x-20">
                <p className="text-[1.6rem] font-bold font-[IstokWeb] text-white w-full lg:w-[33%]">
                    Planning process was coordinated across the district to build a framework of linked Values, Mission, Vision, 
                    Long-term Goals, and Facilities Guiding Principals that unify and strengthen State Center’s efforts to support 
                    students districtwide.
                </p>
                <div className="w-full">
                    <img className="my-[30px] lg:mb-[64px]" src={IntegratedCircles} />
                    <span className="text-white text-[1.563rem] font-[IstokWeb] font-normal mt-[21px]">Qualitative</span>
                    <img className="my-[21px]" src={IntegratedArrow} />
                    <span className="text-[#00AEDA] text-[1.563rem] font-[IstokWeb] font-normal">Quantitative</span>
                </div>
            </div>
        </div>
    );
};

export default Integrated;
