import React, { useState, useEffect } from 'react';
import Footer from "../components/Footer";
import BentoBox from '../components/BentoBox';
import Hero from '../components/HeroCollegeDistrict';
import VisionBackground from "../assets/Backgrounds/CommunityCollegeDistrict/vision.png";
import MisionBackground from "../assets/Backgrounds/CommunityCollegeDistrict/mision.png";
import ValuesBackground from "../assets/Backgrounds/CommunityCollegeDistrict/values.png";
import LongTermGoalsBackground from "../assets/Backgrounds/CommunityCollegeDistrict/longTermGoals.png";
import facilitiesBackground from "../assets/Backgrounds/CommunityCollegeDistrict/facilitiesGuidingPrinciples.png";
import CollegesSection from '../components/CollegesSection';
import PlusIcon from "../assets/Icons/plusIcon.png";
import { useParams } from 'react-router-dom';
import CommunityCollegeContent from '../components/CommunityCollegeContent';
import ValuesImg from "../assets/Backgrounds/CommunityCollegeDistrict/valuesImg.png";
import Goals from '../components/Goals';
import GuidingPrinciples from '../components/GuidingPrinciples';
import { useLocation } from 'react-router-dom';

const CommunityCollegeDistrict = ({}) => {

    let { CommunitySection } = useParams();
    const { pathname } = useLocation();

    const homeLayout = [
        { col: 0, row: 0, colSpan: 0, rowSpan: 1, src: ValuesBackground,  title: "Values", description: ["SCCCD's fundamental beliefs"],
            link: "/community-college-district/values", icon: PlusIcon, height: "371px", 
        }, 
        { col: 1, row: 0, colSpan: 1, rowSpan: 1, src: VisionBackground, title: "Vision", description: ["SCCCD's purpose"],
            link: "/community-college-district/vision", icon: PlusIcon, height: "371px", margin: true
        }, 
        { col: 2, row: 0, colSpan: 2, rowSpan: 1, src: MisionBackground, title: "Mission", description: ["SCCCD's promises"],
            link: "/community-college-district/mission", icon: PlusIcon, height: "371px", margin: true
        },
        { col: 0, row: 1, colSpan: 3, rowSpan: 1, src: LongTermGoalsBackground,  title: "Long-Term Goals", description: ["Broad aspirational statements that describe SCCCD's desired future"],
            link: "/community-college-district/long-term-goals", icon: PlusIcon, height: "371px"
        }, 
        { col: 0, row: 2, colSpan: 3, rowSpan: 1, src: facilitiesBackground,  title: "Facilities guiding principles", description: ["Districtwide design drivers to inform campus development decisions"],
            link: "/community-college-district/facilities-guiding-principles", icon: PlusIcon, height: "371px"
        }, 
    ];

    useEffect(() => {
        window.scrollTo({ top: 645, behavior: 'smooth' });
    }, [pathname]);
    
    return (
        <div>
            <Hero isCommunityPage={true}/>
            {!CommunitySection && 
                <div className="py-[32px] bg-[#E5F4FB] flex justify-center">
                    <div className="w-full px-[8%]">
                        <div className="mt-[64px]">
                            <BentoBox layout={homeLayout}/> 
                        </div>
                    </div>
                </div>
            }
           
            {CommunitySection === "vision" &&
                <div className="py-[32px] bg-[#E5F4FB] flex justify-center">
                    <div className="vision-bg">
                        <div className="w-full px-[8%] h-full py-8">
                            <CommunityCollegeContent title="Vision" text="Advancing equitable student access and achievement is at the heart of our work at State Center Community College District."/>
                        </div>
                    </div>
                </div>
            }

            {CommunitySection === "mission" &&
                <div className="py-[32px] bg-[#E5F4FB] flex justify-center">
                    <div className="vision-bg">
                        <div className="w-full px-[8%] h-full py-8">
                            <CommunityCollegeContent title="Mission" text="We — the faculty, classified professionals, administrators, and trustees at our colleges, off-campus sites, and District Office — are united by this vision."
                                text1="In collaboration across the District and with our community partners, we serve the diverse Central Valley and strengthen our vibrant economy. We efficiently deliver a comprehensive array of post-secondary educational programs and support services to meet a range of student needs and student goals, including associate and baccalaureate degrees, transfer, employment-ready certificates, and lifelong learning."
                                text2="Together, we create innovative, inclusive, and antiracist teaching and learning environments at each of our four colleges — Fresno City College, Reedley College, Clovis Community College, Madera Community College — that are welcoming, accessible, and sudent - centered, designed to bolster our students’ social and economic mobility locally, regionally, and globally."
                            />
                        </div>
                    </div>
                </div>
            }

            {CommunitySection === "values" &&
                <div className="py-[32px] bg-[#E5F4FB] flex justify-center">
                    <div className="bg-[#F2F2F2] w-full">
                        <div className="w-full px-[8%] h-full py-8">
                            <CommunityCollegeContent title="Values" img={ValuesImg} isValues={true}/>
                        </div>
                    </div>
                </div>
            }

            {CommunitySection === "long-term-goals" &&
                <div className="py-[32px] bg-[#E5F4FB] flex justify-center">
                    <div className="bg-[#F2F2F2] w-full">
                        <div className="w-full px-[8%] h-full py-8">
                            <Goals/>
                        </div>
                    </div>
                </div>
            }

            {CommunitySection === "facilities-guiding-principles" &&
                <div className="py-[32px] bg-[#E5F4FB] flex justify-center">
                    <div className="bg-[#F2F2F2] w-full">
                        <div className="w-full px-[8%] h-full py-8">
                            <GuidingPrinciples/>
                        </div>
                    </div>
                </div>
            }

            <div className="py-[32px] bg-white flex justify-center">
                <div className="w-full px-[8%]">
                    <CollegesSection />
                </div>
            </div>
            <Footer />
             
        </div>
    );
};

export default CommunityCollegeDistrict;
