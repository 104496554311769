import React, { useEffect, useState, useRef } from 'react';
import CollegeHeader from './CollegeHeader';
import Footer from "./Footer";
import CollegesSection from './CollegesSection';
import Carousel from './Carousel';
import Card from './Card';
import ImageSelector from './ImageSelector';
import { useParams, useLocation } from 'react-router-dom';
import CollegesData from "../data/collegesData.json";

const CollegeTemplate = ({  }) => {

    let { College, CollegeSection } = useParams();   

    const [ collegeData, setCollegeData ]= useState();
    const [ campusData, setCampusData ]= useState();
    const [ isSatelliteCampus, setIsSatelliteCampus ]= useState();


    const campusTodayRef = useRef(null);
    const visionRef = useRef(null);
    const developmentApproachRef = useRef(null);
    const { pathname } = useLocation();

    const contentRef = useRef(null);

    useEffect(() => {
        switch (College) {
            case "fresno":
                setCollegeData(CollegesData.colleges[0]);
                if(CollegeSection === "main-campus"){
                    setCampusData(CollegesData.colleges[0].campuses[0]);
                }else if(CollegeSection === "west-fresno-center"){
                    setCampusData(CollegesData.colleges[0].campuses[1]);
                    setIsSatelliteCampus(true);
                }else if(CollegeSection === "first-responders-campus"){
                    setCampusData(CollegesData.colleges[0].campuses[2]);
                    setIsSatelliteCampus(true);
                }
                break;
            case "reedley":
                setCollegeData(CollegesData.colleges[1]);
                if(CollegeSection === "reedley-campus"){
                    setCampusData(CollegesData.colleges[1].campuses[0]);
                }
                break;
            case "clovis":
                setCollegeData(CollegesData.colleges[2]);
                if(CollegeSection === "clovis-campus"){
                    setCampusData(CollegesData.colleges[2].campuses[0]);
                }else if(CollegeSection === "herndon-campus"){
                    setCampusData(CollegesData.colleges[2].campuses[1]);
                    setIsSatelliteCampus(true);
                }
                break;
            case "madera":
                setCollegeData(CollegesData.colleges[3]);
                if(CollegeSection === "madera-campus"){
                    setCampusData(CollegesData.colleges[3].campuses[0]);
                }else if(CollegeSection === "oakhurst"){
                    setCampusData(CollegesData.colleges[3].campuses[1]);
                    setIsSatelliteCampus(true);
                }
                break;
            default:
                break;
        }
    }, [College, CollegeSection]); 

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 100)
    }, []);

    return (
        <>
            {collegeData &&
                <div>
                    <CollegeHeader campusTodayRef={campusTodayRef} visionRef={visionRef} developmentApproachRef={developmentApproachRef} title={collegeData.name} logo={collegeData.logo} introductoryText={collegeData.introductoryText} headerBackground={collegeData.headerBackground} acronym={collegeData.acronym} aboutLink={collegeData.aboutLink} color={collegeData.color} showSectionsBtn={!CollegeSection}/>
                    {!CollegeSection &&
                        <div className="py-[32px] bg-white flex justify-center">
                            <div className="w-full px-[8%]">
                                <h2 className="uppercase text-[#143057] text-[3rem] lg:text-[3.5rem] font-['Oswald'] font-bold">{collegeData.name}</h2>
                                <div className="flex justify-center items-center mt-[42px] flex-col space-y-[30px] md:space-y-0 md:grid md:grid-cols-2 lg:justify-start lg:items-start lg:flex-row lg:flex xl:space-x-[79px] md:space-x-[15px]">
                                    {collegeData.campuses?.map((campus, i) => (
                                        <Card key={i} img={campus.background} title={campus.name} link={campus.link} isCollegePage={true} textPosition="overlay" color={collegeData.color}/>
                                    ))}
                                </div>
                                
                            </div>
                        </div>
                    }
                    

                    {CollegeSection && campusData &&
                        <>
                            {campusData.campusToday && 
                                <div ref={campusTodayRef} className="py-[32px] bg-[#F5F5F5] flex justify-center">
                                    <div className="w-full px-[8%]">
                                        <h2 className="uppercase text-[#143057] text-[3rem] lg:text-[3.5rem] font-['Oswald'] font-bold">CAMPUS TODAY</h2>
                                        <div className="pt-[32px]">
                                            <ImageSelector data={campusData.campusToday} color={collegeData.color} />
                                        </div>
                                        
                                    </div>
                                </div>
                            }

                            {campusData.vision && 
                                <div ref={visionRef} className="py-[32px] bg-[#F5F5F5] flex justify-center">
                                    <div className="w-full px-[8%]">
                                        <h2 className="uppercase text-[#143057] text-[3rem] lg:text-[3.5rem] font-['Oswald'] font-bold">{collegeData.acronym} VISION 2035</h2>
                                        <div className="pt-[32px]">
                                            <p className="text-[1.125rem] font-['IstokWeb'] font-bold text-[#143057]">
                                                {campusData.vision.text}
                                            </p>
                                            <div className="pt-[32px]">
                                                <ImageSelector data={campusData.vision.labels} buttonsInMiddle={true} isSatelliteCampus={isSatelliteCampus}/>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            }

                            {campusData.developmentApproach &&
                                <div ref={developmentApproachRef} className="py-[32px] bg-white flex justify-center">
                                    <div className="w-full px-[8%]">
                                        <h2 className="uppercase text-[#143057] text-[3rem] lg:text-[3.5rem] font-['Oswald'] font-bold">DEVELOPMENT APPROACH</h2>
                                        <p className="pt-[32px] text-[1.125rem] font-['IstokWeb'] font-bold text-[#143057]">{campusData.developmentApproach.text}</p>
                                        <span className="text-[1rem] font-['IstokWeb'] font-normal text-[#143057]">The diagrams below describe a potential sequence of development based on a logical movement of functions to limit disruption, limit the number of moves and reduce the need for swing space.</span>
                                        <div className="pt-[32px]">
                                            <Carousel images={campusData.developmentApproach.carouselImgs}/>
                                        </div>
                                        
                                    </div>
                                </div>
                            }
                        </>
                    }
                                  
                    <div ref={contentRef}  className="py-[32px] bg-white flex justify-center">
                        <div className="w-full px-[8%]">
                            <CollegesSection />
                        </div>
                    </div>
                    <Footer />
                </div>
            }
        </>   
    );
};

export default CollegeTemplate;
