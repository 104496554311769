import React, { useState } from "react";
import Arrow from "../assets/Icons/arrowLightBlueIcon.png";
import DownArrow from "../assets/Icons/downArrowLightBlueIcon.png";

const Accordion = ({ items, isPrinciples }) => {
    const [activeIndex, setActiveIndex] = useState(null);
  
    const handleItemClick = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
    };
  
    return (
        <div className="w-full">
            {items.map((item, index) => (
                <div key={index} className="border-b border-gray-200 bg-white rounded-t-lg mb-4">
                    <div
                        className="flex cursor-pointer h-full flex flex-col xl:flex-row"
                        onClick={() => handleItemClick(index)}
                        tabIndex={0}
                        onKeyDown={(e) => {
							if (e.key === 'Enter') {
								handleItemClick(index);
							}
						}}
                    >
                        <div className={`${isPrinciples ? 'bg-[#39C9EE] w-full xl:w-[15%] ' : 'bg-[#008FD1] w-full xl:w-[33%]' } rounded-t-lg flex items-center h-[130px] xl:h-[191px]`}> 
                            {isPrinciples ? 
                                <img
                                    src={item.img}
                                    alt={item.title}
                                    className={`${item.title === "AGILE" ? 'w-28' : 'w-24'} h-24 mx-auto`}
                                />
                            : 
                                <span className="text-white font-bold font-[Oswald] text-[1.8rem] xl:text-[2.5rem] px-4 xl:px-10 uppercase">{item.title}</span>
                            
                            }
                            
                        </div>
                        <div className={`px-4 xl:px-10 self-center py-2 xl:py-0 ${isPrinciples ? 'w-full xl:w-[75%] flex flex-col' : 'w-full xl:w-[60%]'}`}>
                            {isPrinciples &&
                                <span className="text-[#143057] font-bold font-[Oswald] text-[2rem] 2xl:text-[3.186rem] uppercase xl:px-10">{item.title}</span>
                            }
                            <span className={`${isPrinciples && 'xl:px-10' } text-[#143057] font-[IstokWeb] text-[1.5rem] 2xl:text-[2rem] font-bold`}>{item.subtitle}</span>
                        </div>
                        <div className="w-[10%] flex justify-center self-center py-2 xl:py-0">
                            <img
                                src={activeIndex === index ? DownArrow : Arrow}
                                alt={activeIndex === index ? "Open" : "Closed"}
                                className="w-9 h-9"
                            />
                        </div>
                    </div>
                    {activeIndex === index && (
                        <div className={`p-4 flex flex-col ml-auto ${isPrinciples ? 'w-full xl:w-[76%]' : 'w-full xl:w-[66%]'}`}>
                            <h4 className="text-[#143057] font-[IstokWeb] text-[1.3rem] xl:text-[1.75rem] font-bold mb-2">{item.additionalTitle}</h4>
                            <ul className="list-disc list-inside pl-4">
                                {item.additionalContent.map((content, idx) => (
                                    <li key={idx} className="text-[#143057] font-[IstokWeb] text-[1.3rem] xl:text-[1.75rem] font-normal">{content}</li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
  };
  
  export default Accordion;
