import SectionHeader from "./SectionHeader";
const Team = () => {
    return (
        <div>
            <SectionHeader title="The Planning Process" subtitle="Participatory" backTo="Back to Planning Process" 
                backToLink="/planning-process" goTo="Back to Collaboration" goToLink="/planning-process/participatory"
                page="team"
            />

            <p className="my-[21px] text-white text-[1.5rem] font-bold font-['IstokWeb']">
                Thank You! Vision 2035 Commission AND to the College Planning Teams!
            </p>
            
            <div className="team-container grid grid-cols-2 md:grid-cols-5 gap-8 text-[1rem] text-white font-normal font-['IstokWeb']">
                <div className="column">
                    <ul>
                        <li>Alex Adams</li>
                        <li>Alexis Calvillo</li>
                        <li>Bethany Johns</li>
                        <li>Bill Turini</li>
                        <li>Caryss Johnson</li>
                        <li>Christine Miktarian</li>
                        <li>Christopher Bosworth</li>
                        <li>Cyndie Luna</li>
                        <li>Dale Van Dam</li>
                        <li>Elizabeth Villalobos</li>
                        <li>Ghada Al-Masri</li>
                        <li>Gurdeep He’bert</li>
                        <li>Janice Offenbach</li>
                        <li>Juliana Mosier</li>
                        <li>Karla Kirk</li>
                        <li>Keith Ford</li>
                        <li>Lorrie Hopper</li>
                    </ul>
                </div>
                <div className="column">
                    <ul>
                        <li>Marie Harris</li>
                        <li>Matthew Richmond</li>
                        <li>Melanie Highfill</li>
                        <li>Michael Takeda</li>
                        <li>Michelle Johnson</li>
                        <li>Mikaela Sophia Ceas Silva</li>
                        <li>Monica Chahal</li>
                        <li>Monique Reyna</li>
                        <li>Natalie Culver-Dockins</li>
                        <li>Omar Gutierrez</li>
                        <li>Ray Ramirez</li>
                        <li>Rebecca Snyder</li>
                        <li>Robert Frost</li>
                        <li>Shannon Robertson</li>
                        <li>Stephanie Curry</li>
                        <li>Teresa Mendes</li>
                        <li>Virginia Beamer</li>
                    </ul>
                </div>
                <div className="column col-span-3">
                    <h3 className="text-[1.3rem] mb-6 font-bold text-[IstokWeb] underline">Additional College Planning Team Members:</h3>
                    <div className="sub-columns grid grid-cols-4 gap-4 text-[1rem] text-white font-normal font-['IstokWeb']">
                        <div className="sub-column">
                            <h4 className="font-bold">FRESNO</h4>
                            <ul>
                                <li>Barbara Hioco</li>
                                <li>Cris Monahan-Bremer</li>
                                <li>Delia Makel</li>
                                <li>Donna Cooper</li>
                                <li>Gurminder Sangha</li>
                                <li>Lataria Hall</li>
                                <li>Quentin Deforest</li>
                                <li>Robert Pimentel</li>
                                <li>Sean Henderson</li>
                            </ul>
                        </div>
                        <div className="sub-column">
                            <h4 className="font-bold">REEDLEY</h4>
                            <ul>
                                <li>Amanda Taintor</li>
                                <li>Ignacio Faria</li>
                                <li>Jerry Buckely</li>
                                <li>Jesus Vega</li>
                                <li>Josh Darnell (KCUSD)</li>
                                <li>Onesta Francis</li>
                                <li>Veronica Leal</li>
                            </ul>
                        </div>
                        <div className="sub-column">
                            <h4 className="font-bold">CLOVIS</h4>
                            <ul>
                                <li>James Sewall</li>
                                <li>Kim Amstrong</li>
                                <li>Marco De La Garza</li>
                                <li>Richard Mostert</li>
                                <li>Ryan Feyk-Miney</li>
                                <li>Sergio Salinas</li>
                                <li>Stephanie Babb</li>
                                <li>Terng Her</li>
                                <li>Tomoya Ryan</li>
                            </ul>
                        </div>
                        <div className="sub-column">
                            <h4 className="font-bold">MADERA</h4>
                            <ul>
                                <li>Angel Reyna</li>
                                <li>Gregory Ramirez</li>
                                <li>Vanessa Barraza Tapia</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Team;
