import React, { useState, useEffect } from 'react';
import SearchIcon from "../assets/Icons/searchIcon.png";
import SearchIconHover from "../assets/Icons/searchIconHover.png";
import DropDownIcon from "../assets/Icons/dropdownIcon.png";
import DropDownIconHover from "../assets/Icons/dropdownIconHover.png";
import DropDownCloseIcon from "../assets/Icons/dropdownCloseIcon.png";
import DropDownCloseIconHover from "../assets/Icons/dropdownCloseIconHover.png";
import StateCenterLogo from "../assets/Logos/stateCenterLogo.png";
import Fuse from "fuse.js";


const keys = {
	TITLE: "title",
	TAGS: "tags"
};

const fuseOptions = {
	keys: [keys.TITLE, keys.TAGS]
};

const screens = [
	{
		title: 'State Center Vision 2035',
		tags: ['State', 'Center', '2035', 'Home'],
		navigation: '/home'
	},
	{
		title: 'Community College District',
		tags: ['Community', 'College', 'District'],
		navigation: '/community-college-district'
	},
	{
		title: 'Districtwide Map',
		tags: ['Map', 'District'],
		navigation: '/district-wide-map'
	},
	{
		title: 'Planning Process',
		tags: ['Planning', 'Process'],
		navigation: '/planning-process'
	},
	{
		title: 'Vision',
		tags: ['Vision'],
		navigation: '/community-college-district/vision'
	},
	{
		title: 'Mission',
		tags: ['Mission'],
		navigation: '/community-college-district/mission'
	},
	{
		title: 'Values',
		tags: ['Values'],
		navigation: '/community-college-district/values'
	},
	{
		title: "Long Term Goals",
		tags: ['Long', 'Term', 'Goals'],
		navigation : "/community-college-district/long-term-goals"
	},
	{
		title: "Facilities Guiding Principles",
		tags: ['Facilities', 'Guiding', 'Principles'],
		navigation : "/community-college-district/facilities-guiding-principles"
	},
	{
		title: "State Center Colleges",
		tags: ['Colleges'],
		navigation : "/colleges"
	},
	{
		title: "Fresno Community College",
		tags: ['Fresno', 'College', 'Community'],
		navigation : "/colleges/fresno"
	},
	{
		title: "Clovis Community College",
		tags: ['Clovis', 'College', 'Community'],
		navigation : "/colleges/clovis"
	},
	{
		title: "Reedley Community College",
		tags: ['Reedley', 'College', 'Community'],
		navigation : "/colleges/reedley"
	},
	{
		title: "Madera Community College",
		tags: ['Madera', 'College', 'Community'],
		navigation : "/colleges/madera"
	},
];

const Navbar = ({isCommunityPage, increaseHeight, setIncreaseHeight}) => {
	const [isSearchHovered, setIsSearchHovered] = useState(false);
	const [isDropdownHovered, setIsDropdownHovered] = useState(false);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const [dropdownJustOpened, setDropdownJustOpened] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

	const fuse = new Fuse(screens, fuseOptions);
	const searchResults = searchQuery ? fuse.search(searchQuery) : [];


	useEffect(() => {
		if (isDropdownOpen && dropdownJustOpened) {
			const focusableItems = Array.from(document.querySelectorAll('[role="menuitem"]'));
			if (focusableItems.length > 0) {
				focusableItems[0].focus();
			}
			setDropdownJustOpened(false);  // Reset the state after focusing
		}
	}, [isDropdownOpen, dropdownJustOpened]);

	const handleToggle = () => {
		setIsDropdownOpen(!isDropdownOpen);
		setDropdownJustOpened(true); 
		setIncreaseHeight(!increaseHeight);
	};
  
	const handleMouseEnter = (hoverElement) => {
		if(hoverElement === "dropdown"){
			setIsDropdownHovered(true);
		}else{
			setIsSearchHovered(true);
		}
	};

	const handleMouseLeave = (hoverElement) => {
		if(hoverElement === "dropdown"){
			setIsDropdownHovered(false);
		}else{
			setIsSearchHovered(false);
		}
	};

	const handleInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

	const handleKeyDown = (event) => {
		if (event.key === 'Enter' || event.key === ' ') {
			handleToggle();
			event.preventDefault();
		}
	
		if (isDropdownOpen) {
			const focusableItems = Array.from(document.querySelectorAll('[role="menuitem"]'));
			if (focusableItems.length === 0) {
				return;
			}
	
			const currentIndex = focusableItems.indexOf(document.activeElement);
	
			if (event.key === 'ArrowDown') {
				const nextIndex = (currentIndex + 1) % focusableItems.length;
				focusableItems[nextIndex].focus();
				event.preventDefault();
			} else if (event.key === 'ArrowUp') {
				const prevIndex = (currentIndex - 1 + focusableItems.length) % focusableItems.length;
				focusableItems[prevIndex].focus();
				event.preventDefault();
			} else if (event.key === 'Escape') {
				handleToggle();
				event.preventDefault();
			}
		}
	};

  	return (
		<div className="w-full flex justify-between items-center flex-col lg:flex-row">
			<div className="flex">
				<div className="">
					<img alt="State Center Logo" src={StateCenterLogo} className='h-[144.68px] w-[119.04px]'></img>
				</div>
			</div>
			<nav className="h-full">
				<ul className={`flex items-center font-['IstokWeb'] font-bold text-[1rem] md:text-[1.234rem] ${isCommunityPage ? 'text-white' : 'text-white'}`}>
					<li className={`${isCommunityPage ? 'hover:text-[#0C3060]' : 'hover:text-[#00AEDA]'}`}><a href="/" className="px-3 py-2">Home</a></li>
					<li tabIndex={0} 
						className={`${isCommunityPage ? 'hover:text-[#0C3060]' : 'hover:text-[#00AEDA]'}`} 
						onClick={handleToggle} 
						onMouseEnter={() => handleMouseEnter("dropdown")}
						onMouseLeave={() => handleMouseLeave("dropdown")}
						onKeyDown={handleKeyDown}
					>
						<div className="relative inline-block text-left">
							<div className="px-3 py-2 cursor-pointer flex items-center">
								<p>State Center Colleges</p>
								{!isDropdownOpen ?
									<img className="w-[15px] h-[10px] ml-2" src={isCommunityPage ? DropDownIconHover : isDropdownHovered ? DropDownIcon : DropDownIconHover} alt="Chevron Icon"/>
									:
									<img className="w-[15px] h-[10px] ml-2" src={isCommunityPage ? DropDownCloseIconHover : isDropdownHovered ? DropDownCloseIcon : DropDownCloseIconHover}  alt="Chevron Icon"/>
								}
							</div>
							{isDropdownOpen &&
								<div className="absolute z-10 w-56 origin-top-right focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
									<div className="py-1" role="none">
										<a href="/colleges/fresno" className={`${isCommunityPage ? 'text-white hover:text-[#0C3060]' : 'hover:text-[#00AEDA] text-white'} block pl-[36px] py-1`} role="menuitem" tabIndex="0" id="menu-item-0">Fresno</a>
										<a href="/colleges/clovis" className={`${isCommunityPage ? 'text-white hover:text-[#0C3060]' : 'hover:text-[#00AEDA] text-white'} block pl-[36px] py-1`} role="menuitem" tabIndex="0" id="menu-item-1">Clovis</a>
										<a href="/colleges/madera" className={`${isCommunityPage ? 'text-white hover:text-[#0C3060]' : 'hover:text-[#00AEDA] text-white'} block pl-[36px] py-1`} role="menuitem" tabIndex="0" id="menu-item-2">Madera</a>
										<a href="/colleges/reedley" className={`${isCommunityPage ? 'text-white hover:text-[#0C3060]' : 'hover:text-[#00AEDA] text-white'} block pl-[36px] py-1`} role="menuitem" tabIndex="0" id="menu-item-3">Reedley</a>
									</div>
								</div>
							}
						</div>
					</li>
					<li>
						<div className="relative inline-block text-left">
							<div className="relative">
								<div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
									<img alt="Search Icon" className="w-[19.71px] h-[19.71px]" src={isCommunityPage ? SearchIconHover : isSearchHovered ? SearchIconHover : SearchIconHover}
										onMouseEnter={() => handleMouseEnter("search")}
										onMouseLeave={() => handleMouseLeave("search")}
									/>
								</div>
								<label htmlFor="default-search" className="sr-only">Search</label>  {/* Hidden label */}
								<input type="search" id="default-search" className={`${isCommunityPage ? 'text-white placeholder-white' : 'text-white placeholder-white'}  text-[1rem] md:text-[1.234rem] block w-full p-4 ps-10 text-sm bg-transparent focus:ring-blue-500 focus:border-blue-500`} 
									placeholder="Search" required 
									onChange={handleInputChange}
								/>
							</div>
							{
								!!searchQuery && searchResults.length > 0 && 
								(
									<ul className="search-results absolute origin-top-right bg-white border border-gray-100 w-full mt-2">
										{searchResults.map((result) => {
											return (
												<li key={result.item.navigation} className="text-[1rem] pl-8 pr-2 py-1 border-b-2 border-gray-100 relative cursor-pointer text-[#00AEDA] hover:text-[#0C3060]">
													<a href={result.item.navigation}>{result.item.title}</a>
												</li>
											);
										})}
									</ul>
								)
							}
							{
								!!searchQuery && searchResults.length === 0 &&
								<ul className="text-[1rem] search-results absolute origin-top-right bg-white border border-gray-100 w-full mt-2">
									<li className="no-data">
										No related topic found. 
									</li>
								</ul> 
							}
						</div>
					</li>

				</ul>
			</nav>
		</div>		
  	);
};

export default Navbar;
