import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import { useParams } from 'react-router-dom';

const CollegeHeader = ({ logo, headerBackground, introductoryText, aboutLink, acronym, color, showSectionsBtn, campusTodayRef, visionRef, developmentApproachRef }) => {
    let { CollegeSection } = useParams();  
    const [showCampusTodayBtn, setShowCampusTodayBtn] = useState(true);
    const [showDevelopmentBtn, setShowDevelopmentBtn] = useState(true);
    const [title, setTitle] = useState("");
    const [increaseHeight, setIncreaseHeight] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [headerStyle, setHeaderStyle] = useState({});

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const newHeaderStyle = {
            backgroundImage: `url(${headerBackground})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: isMobile && increaseHeight  && (title !== "" || CollegeSection === "madera-campus" || CollegeSection === "reedley-campus") ? `900px` : isMobile && increaseHeight ? '846px' : '710px',
        };

        setHeaderStyle(newHeaderStyle);
    }, [headerBackground, isMobile, increaseHeight, title]);
    
    const redirect = (link) => {
		window.location.href = link;
	}

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    useEffect(() => {
        switch (CollegeSection) {
            case "west-fresno-center":
                setTitle("FCC WEST FRESNO CENTER CAMPUS");
                setShowDevelopmentBtn(false);
                break;
            case "first-responders-campus":
                setTitle("FCC FIRST RESPONDERS CAMPUS");
                setShowDevelopmentBtn(false);
                break;
            case "oakhurst":
                setTitle("OAKHURST CAMPUS");
                setShowDevelopmentBtn(false);
                setShowCampusTodayBtn(false);
                break;
            default:
                setTitle("");
                break;
        }
    }, [CollegeSection]); 

    return (
        <header style={headerStyle}>
            <div className="h-full flex justify-center">
                <div className="w-full pr-[8%] pl-[8%] h-full flex flex-col justify-between items-center py-[77px]">
                    <Navbar isCommunityPage={true} setIncreaseHeight={setIncreaseHeight} increaseHeight={increaseHeight}/>
                    <div className="w-full flex flex-row justify-between">
                        <div className="md:mt-4">
                            <h2 className="uppercase text-white text-[1rem] lg:text-[3rem] font-['Oswald'] font-bold">{title}</h2>
                            <img src={logo} className="mb-8 md:h-[147px]"/>
                            <div className="flex flex-wrap">
                                <button onClick={() => redirect(aboutLink)} className="border border-white rounded-full px-6 text-white font-[IstokWeb] font-normal text-[1rem] mb-2 mr-2" style={{backgroundColor: color, height: '47px'}}>About {acronym}</button>
                                {!showSectionsBtn && 
                                    <>
                                        {showCampusTodayBtn && 
                                            <button onClick={() => scrollToSection(campusTodayRef)} className="border border-white rounded-full px-6 text-white font-[IstokWeb] font-normal text-[1rem] mb-2 mr-2" style={{backgroundColor: color}}>
                                                Campus Today
                                            </button>
                                        }

                                        <button onClick={() => scrollToSection(visionRef)} className="border border-white rounded-full px-6 text-white font-[IstokWeb] font-normal text-[1rem] mb-2 mr-2" style={{backgroundColor: color}}>
                                            Vision 2035
                                        </button>
                                    
                                        
                                        {showDevelopmentBtn && 
                                            <button onClick={() => scrollToSection(developmentApproachRef)} className="border border-white rounded-full px-6 text-white font-[IstokWeb] font-normal text-[1rem] mb-2 mr-2" style={{backgroundColor: color}}>
                                                Development Approach
                                            </button>
                                        }
                                    </>
                                }
                            </div>
                            <p className="text-white font-['IstokWeb'] font-normal text-[1rem] md:text-[1.2rem] box-border md:pt-[30px]">
                                {introductoryText}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default CollegeHeader;
