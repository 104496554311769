import SectionHeader from "./SectionHeader";
import Circle from "./Circle";
import { useState } from "react";

const PlanningProcessSteps = () => {
    const [selectedStep, setSelectedStep] = useState(null);

    const handleHover = (number) => {
        setSelectedStep(number);
    };

    const handleClick = (number) => {
        setSelectedStep(number);
    };

    // Define the data for each step
    const stepsData = [
        {
            title: "Prepare",
            sentences: ["Review current Districtwide strategic and facilities plans", "Define project goals, measures of success, and constituent groups", "Design a variety of listening activities to engage stakeholders across the District"],
            number:"01", text:"Prepare", color:"#6CD4EF", textColor:"#143057"
        },
        {
            title: "Analyze",
            sentences: ["Engage with stakeholders through open campus forums and surveys", "Analyze existing conditions, patterns, and experiences", "Identify key challenges and opportunities"],
            number:"02", text:"Analyze", color:"#32BCDF", textColor:"#143057"
        },
        {
            title: "Frame",
            sentences: ["Develop Districtwide mission, vision, and values", "Apply this framework to analyze data", "Use data to develop Districtwide Long-term Goals and Facilities Guiding Principles"],
            number:"03", text:"Frame", color:"#00AEDA", textColor:"#143057"
        },
        {
            title: "Explore",
            sentences: ["Develop preliminary facilities recommendations", "Include strategies to maximize funding opportunities", "Review the preliminary solutions with constituent campus groups"],
            number:"04", text:"Explore", color:"#008FD1", textColor:"#fff"
        },
        {
            title: "Recommend",
            sentences: ["Develop final recommendations for campus sites and facilities", "Draft SCCCD Vision 2035", "Facilitate reviews and approvals"],
            number:"05", text:"Recommend", color:"#143057", textColor:"#fff"
        }
    ];

    return (
        <div>
            <SectionHeader title="The Planning Process" subtitle="5-step approach" backTo="Back to Planning Process" backToLink="/planning-process" />
            
            <div className="mt-[21px] rounded-xl bg-white h-[600px] md:h-[542px] lg:h-[600px] 2xl:h-[552px] px-[4%]">

                <h3 className="pt-[21px] text-[#00AEDA] font-[IstokWeb] font-normal text-[1rem] md:text-[1.5rem] lg:text-[2rem] uppercase">Districtwide + Campus + community Input And review Throughout</h3>

                <div className="flex flex-col md:flex-col items-center mt-[21px] ">
                    <div className="grid grid-cols-3 md:flex md:flex-row mt-[12px] gap-[21px]">
                        {stepsData.map((step, i) => (
                            <Circle number={step.number} text={step.text} color={step.color} textColor={step.textColor} onHover={handleHover} onClick={handleClick} />

                        ))}
                    </div>

                    {selectedStep !== null && (  
                        <div className="text-left mt-4 w-full pl-[3rem] xl:pl-[5rem]">
                            <h4 style={{color : stepsData[selectedStep - 1].color}} className="mb-4 font-[Oswald] font-semibold text-[1.5rem] md:text-[2.5rem] lg:text-[2.793rem] uppercase">{stepsData[selectedStep - 1].title}</h4>
                            <ul>
                                {stepsData[selectedStep - 1].sentences.map((sentence, index) => (
                                    <li key={index} className="list-disc font-[IstokWeb] font-bold text-[#005084] text-[1rem] md:text-[1.4rem] lg:text-[1.676rem]">{sentence}</li>
                                ))}
                            </ul>
                        </div>
                    )}  
                </div>
            </div>
        </div>
    );
};

export default PlanningProcessSteps;
