import React, { useEffect, useRef } from 'react';
import Footer from "../components/Footer";
import BentoBox from '../components/BentoBox';
import Hero from '../components/Hero';
import CollegesSection from '../components/CollegesSection';
import CommunityBackground from "../assets/Backgrounds/communityBackground.png";
import DistrictBackground from "../assets/Backgrounds/districtBackground.png";
import PlanningBackground from "../assets/Backgrounds/planningBackground.png";
import PlusIcon from "../assets/Icons/plusIcon.png";
import ArrowIcon from "../assets/Icons/arrowIcon.png";
import { useParams } from 'react-router-dom';
import DistrictMap from '../components/DistrictMap';
import PlanningProcess from '../components/PlanningProcess';
import Integrated from '../components/Integrated';
import Participatory from '../components/Participatory';
import Team from '../components/Team';
import DataInformed from '../components/DataInformed';
import PlanningProcessSteps from '../components/PlanningProcessSteps';
import { useLocation } from 'react-router-dom';

const LandingPage = ({}) => {
    let { PanelType, PlanningProcessSection, ParticipatoryTeam  } = useParams();
    const { pathname } = useLocation();

    const homeLayout = [
        { col: 0, row: 0, colSpan: 1, rowSpan: 3, src: CommunityBackground, title: "State Center Community College District (SCCCD)", description: ["About", "Vision", "Mission", "Values", "Long Term Goals", "Facilities Guiding Principles"], 
            link: "/community-college-district", icon: ArrowIcon, height: "742px"
        }, 
        { col: 1, row: 0, colSpan: 3, rowSpan: 1, src: DistrictBackground, title: "Districtwide Map",
            link: "/district-wide-map", icon: PlusIcon, height: "371px", margin: true
        },
        { col: 1, row: 1, colSpan: 2, rowSpan: 1, src: PlanningBackground,  title: "The Planning Process",
            link: "/planning-process", icon: PlusIcon, height: "371px", margin: true
        }, 
    ];

    useEffect(() => {
        window.scrollTo({ top: 645, behavior: 'smooth' });
    }, [pathname]);

    return (
        <div>
            <Hero />
            <div className="py-[32px] bg-[#E5F4FB] flex justify-center">
                <div className="w-full px-[8%]">
                    <h2 className="uppercase text-[#143057] text-[3rem] lg:text-[3.5rem] font-['Oswald'] font-bold">What is State Center Vision 2035?</h2>
                    <div className="pt-[32px]">
                        <p className="text-[1.125rem] font-['IstokWeb'] font-bold text-[#143057]">
                            This integrated plan aligns District Services and all State Center Colleges, leveraging a shared mission, vision, and values as our foundation for success.
                        </p>
                        <p className="text-[#143057] text-[1.125rem] font-['IstokWeb'] pt-[32px]">
                            This framework, crafted through extensive internal and external collaboration, addresses present and future challenges.
                            <br/>
                            Our mission, vision, and values drive Districtwide and College long-term goals and shared measures of success.
                        </p>
                    </div>
                    <div className="mt-[64px]">
                        {PanelType === "home" && 
                            <BentoBox layout={homeLayout}/> 
                        }
                    </div>
                </div>
            </div>
            {PanelType === "district-wide-map" &&
                <div className="py-[32px] bg-[#143057] flex justify-center">
                    <div className="w-full px-[8%]">
                        <DistrictMap />
                    </div>
                </div>
            }
            {PanelType === "planning-process" && !PlanningProcessSection &&
                <div className="py-[32px] bg-[#143057] flex justify-center">
                    <div className="w-full px-[8%]">
                        <PlanningProcess />
                    </div>
                </div>
            }
            {(PanelType === "planning-process" && PlanningProcessSection === "integrated") && (
                <div className="py-[32px] bg-[#143057] flex justify-center">
                    <div className="w-full px-[8%]">
                        <Integrated />
                    </div>
                </div>
            )}
            {(PanelType === "planning-process" && PlanningProcessSection === "participatory" && !ParticipatoryTeam) && (
                <div className="py-[32px] bg-[#143057] flex justify-center">
                    <div className="w-full px-[8%]">
                        <Participatory />
                    </div>
                </div>
            )}
            {(PanelType === "planning-process" && PlanningProcessSection === "participatory" && ParticipatoryTeam === "team") && (
                <div className="py-[32px] bg-[#143057] flex justify-center">
                    <div className="w-full px-[8%]">
                        <Team />
                    </div>
                </div>
            )}
            {(PanelType === "planning-process" && PlanningProcessSection === "data-informed") && (
                <div className="py-[32px] bg-[#143057] flex justify-center">
                    <div className="w-full px-[8%]">
                        <DataInformed />
                    </div>
                </div>
            )}
            {(PanelType === "planning-process" && PlanningProcessSection === "steps") && (
                <div className="py-[32px] bg-[#143057] flex justify-center">
                    <div className="w-full px-[8%]">
                        <PlanningProcessSteps />
                    </div>
                </div>
            )}
            <div className="py-[32px] bg-white flex justify-center">
                <div className="w-full px-[8%]">
                    <CollegesSection />
                </div>
            </div>
            
                
            <Footer />
             
            {/*
            <BentoBox layout={layout} showPopUpCallback={showPopUpCallback}/>

            <br></br>
            <Colleges showPopUpCallback={showPopUpCallback}/>
            <Footer />
   

            {showPopUp && 
                <SimplePopUp showPopUpCallback={showPopUpCallback}/>
            }*/}
        </div>
  );
};

export default LandingPage;
