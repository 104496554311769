import React from 'react';

const Circle = ({ number, text, color, textColor, onHover, onClick }) => {

    const circleStyle = {
        color: textColor,
        backgroundColor: color,
    };

    return (
        <div
            className={`shadow-xl circle relative cursor-pointer flex flex-col justify-center items-center w-24 h-24 lg:w-36 lg:h-36 xl:w-44 xl:h-44 2xl:w-52 2xl:h-52 rounded-full hover:border-2 hover:border-[#A2ECFF]`}
            style={circleStyle}
            onMouseEnter={() => onHover(number)}
            onMouseLeave={() => onHover(null)}
            onClick={() => onClick(number)}
            tabIndex={0}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    onClick(number);
                }
            }}
        >
            <span className="lg:text-[3rem] xl:text-[3.5rem] 2xl:text-[4.189rem] font-thin font-[Oswald]">{number}</span>
            <span className="lg:text-[1rem] xl:text-[1.5rem] 2xl:text-[1.676rem] font-semibold font-[Oswald]">{text}</span>
        </div>
    );
};

export default Circle;
