import SectionHeader from "./SectionHeader";

const CommunityCollegeContent = ({title, text, text1, text2, img, isValues}) => {
    return (
        <div className="h-full">
            <SectionHeader backTo="Back" backToLink="/community-college-district" blueText={isValues}/>
            <div className={`md:flex-col flex flex-col items-start mt-[21px]`}>
                <p className={`text-[3.75rem] font-bold font-[Oswald] ${isValues ? 'text-[#143057]' : 'text-white'} uppercase mb-12`}>
                    {title}
                </p>
                {isValues && 
                    <div className="flex w-full justify-center mt-[-12%]">
                        <img className="md:h-[45rem]" src={img}/>
                    </div>
                }
                
                <div className="w-full">
                    <p className={`${title === "Vision" ? 'text-[3rem]' : 'text-[2rem]'} font-normal font-[Oswald] text-white`}>
                        {text}
                    </p>
                    <br/>
                    <p className="text-[2rem] font-normal font-[Oswald] text-white">
                        {text1}
                    </p>
                    <br/>
                    <p className="text-[2rem] font-normal font-[Oswald] text-white">
                        {text2}
                    </p>
                </div>
                 
            </div>
        </div>
    );
};

export default CommunityCollegeContent;
