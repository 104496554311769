import React, { useState } from 'react';
import Card from "./Card";
import ArrowBlueIcon from "../assets/Icons/arrowBlueIcon.png";
import ArrowLightBlueIcon from "../assets/Icons/arrowLightBlueIcon.png";
import ReedleyLogo from "../assets/Logos/reedleyLogo.png";
import ClovisLogo from "../assets/Logos/clovisLogo.png";
import MaderaLogo from "../assets/Logos/maderaLogo.png";
import FresnoLogo from "../assets/Logos/fresnoLogo.png";

const CollegesSection = ({isCollegePage}) => {

	const [hoveredTitle, setHoveredTitle] = useState(false);
    const [iconSrc, setIconSrc] = useState(ArrowBlueIcon);
	const colleges = [
		{ title:"Fresno City College", img:FresnoLogo, link:"/colleges/fresno" },
		{ title:"Reedley College", img:ReedleyLogo, link:"/colleges/reedley" },
		{ title:"Clovis Community College", img:ClovisLogo, link:"/colleges/clovis" },
		{ title:"Madera Community College", img:MaderaLogo, link:"/colleges/madera" }
	] 

	const handleMouseEnter = () => {
        setHoveredTitle(true);
        setTimeout(() => {
            setIconSrc(ArrowLightBlueIcon); 
        }, 300); 
    };

    const handleMouseLeave = () => {
        setHoveredTitle(false);
		setTimeout(() => {
            setIconSrc(ArrowBlueIcon); 
        }, 300); 
    };

 	return (
		<section className={`${!isCollegePage && 'bg-white'}`}>
			<div className="flex items-center"
				onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
			>
				<a href="/colleges" className={`uppercase text-[3.5rem] font-['Oswald'] font-bold transition ease-in-out delay-150 duration-1000 ${hoveredTitle ? 'text-[#008FD1]' : 'text-[#143057]'}`}>State Center Colleges</a>
				<img className={`ml-6 w-[36px] h-[36px] transition-transform duration-[1500ms] ${hoveredTitle ? 'translate-x-10' : '' }`} src={iconSrc} alt="Icon" />
			</div>
			<div className="flex justify-center items-center mt-[42px] flex-col md:grid md:grid-cols-2 lg:justify-start lg:items-start lg:flex-row lg:flex xl:space-x-[79px]">
				{colleges?.map((college, i) => (
					<Card key={i} img={college.img} title={college.title} isCollegePage={isCollegePage} link={college.link}/>
				))}
			</div>
		</section>
  	);
};

export default CollegesSection;
