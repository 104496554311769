import SectionHeader from "./SectionHeader";
import IntegratedCircles from "../assets/Integrated/integratedCircles.png";
import IntegratedArrow from "../assets/Integrated/integratedArrow.png";

const DataInformed = () => {
    return (
        <div>
            <SectionHeader title="The Planning Process" subtitle="Data Informed" backTo="Back to Planning Process" backToLink="/planning-process"/>

            <p className="my-[21px] text-white text-[1.5rem] font-bold font-['IstokWeb']">
                Vision 2035 integrates data gathered by a variety of research methods.
            </p>
            
            <div className="team-container grid grid-cols-1 md:grid-cols-3 gap-8 text-[1rem] text-white font-normal font-['IstokWeb']">
                <div className="column">
                    <h3 className="text-[2rem] font-[Oswald] font-bold mb-4 uppercase text-[#008FD1]">Qualitative</h3>
                    <ul className="font-bold text-[1.5rem]">
                        <li>Districtwide Survey</li>
                        <li>Campus Forums</li>
                        <li>Community Listening Sessions</li>
                        <li>Observations</li>
                    </ul>
                </div>
                <div className="column">
                    <h3 className="text-[2rem] font-[Oswald] font-bold mb-4 uppercase text-[#008FD1]">Quantitative</h3>
                    <p className="font-bold text-[1.5rem]">Key Performance Indicators (KPI’s)</p>
                    <ul className="list-disc ml-6 text-[1.5rem]">
                        <li>Enrollment</li>
                        <li>Retention and Success</li>
                        <li>Persistence and Completion</li>
                        <li>Employment</li>
                    </ul>
                    <br/>
                    <p className="font-bold text-[1.5rem]">Labor Market Trends</p>
                    <p className="font-bold text-[1.5rem]">Census Data</p>
                </div>
                <div className="column">
                    <h3 className="text-[2rem] font-[Oswald] font-bold mb-4 uppercase text-[#008FD1]">Facilities Planning Data</h3>
                    <p className="font-bold text-[1.5rem]">Physical</p>
                    <ul className="list-disc ml-6 text-[1.5rem]">
                        <li>Campus Development History</li>
                        <li>Facility Condition Index (FCI)</li>
                    </ul>
                    <br/>
                    <p className="font-bold text-[1.5rem]">Experiential</p>
                    <ul className="list-disc ml-6 text-[1.5rem]">
                        <li>Perceptions</li>
                        <li>Ideas</li>
                    </ul>
                    <br/>
                    <p className="font-bold text-[1.5rem]">Quantifiable</p>
                    <ul className="list-disc ml-6 text-[1.5rem]">
                        <li>Space Inventory</li>
                        <li>Utilization</li>
                        <li>Forecasts</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default DataInformed;
