import React, { useState } from 'react';
import IntegratedBackground from "../assets/Backgrounds/PlanningProcess/integratedBackground.png";
import ParticipatoryBackground from "../assets/Backgrounds/PlanningProcess/participatoryBackground.png";
import DataBackground from "../assets/Backgrounds/PlanningProcess/dataBackground.png";
import StepBackground from "../assets/Backgrounds/PlanningProcess/5StepBackground.png";
import BentoBox from './BentoBox';
import SectionHeader from './SectionHeader';

const PlanningProcess = () => {
   
    const planningProcessLayout = [
        { col: 0, row: 0, colSpan: 0, rowSpan: 0, height: "290px", src: IntegratedBackground, title: "Integrated",
            link: "/planning-process/integrated"
        }, 
        { col: 1, row: 0, colSpan: 1, rowSpan: 0, height: "290px", src: ParticipatoryBackground, title: "Participatory", margin: true,
            link: "/planning-process/participatory"
        }, 
        { col: 2, row: 0, colSpan: 2, rowSpan: 0, height: "290px", src: DataBackground, title: "Data-Informed", margin: true,
            link: "/planning-process/data-informed"
        }, 
        { col: 0, row: 1, colSpan: 3, rowSpan: 1, height: "290px", src: StepBackground, title: "5-Step Approach", 
            link: "/planning-process/steps", 
        },  
    ];

    return (
        <div>
            <SectionHeader title="The Planning Process"/>
            <div className="flex flex-col md:flex-row items-start mt-[21px]">
                <BentoBox layout={planningProcessLayout}/> 
            </div>
        </div>
    );
};

export default PlanningProcess;
