import SectionHeader from "./SectionHeader";
import Accordion from "./Accordion";

const Goals = ({ }) => {
    const items = [
        {
            title: "Equitable Student Access",
            subtitle: "SCCCD will increase equitable student access to educational experiences and resources.",
            additionalTitle: "DISTRICTWIDE STRATEGIES:",
            additionalContent: [
                "Welcome all students to State Center Colleges",
                "Offer a range of student support",
                "Help students choose and enter their pathway",
                "Strengthen community on campus"
            ]
        },
        {
            title: "Equitable Student Achievement",
            subtitle: "SCCCD will prioritize and institutionalize policies and practices that result in equitable student achievement.",
            additionalTitle: "DISTRICTWIDE STRATEGIES:",
            additionalContent: [
                "Address racial disparity in student success measures",
                "Improve all student success outcomes",
                "Provide and prioritize professional development"
            ]
        },
        {
            title: "WORKFORCE + SOCIAL MOBILITY",
            subtitle: "SCCCD will support and advance students’ economic and social mobility.",
            additionalTitle: "DISTRICTWIDE STRATEGIES:",
            additionalContent: [
                "Create clear pathways",
                "Strengthen relationships with local employers",
                "Leverage partnerships",
                "Prioritize applied learning"
            ]
        },
        {
            title: "FISCAL STRENGTH",
            subtitle: "SCCCD will implement policies and practices that increase fiscal resources and reinforce fiscal stability.",
            additionalTitle: "DISTRICTWIDE STRATEGIES:",
            additionalContent: [
                "Keep current students on the path to timely completion",
                "Recruit and retain underserved populations",
                "Maximize state funding",
                "Seek diverse sources of funding"
            ]
        },
        {
            title: "TRUST + COLLABORATION",
            subtitle: "SCCCD will increase and strengthen districtwide unity, transparency, and accountability.",
            additionalTitle: "DISTRICTWIDE STRATEGIES:",
            additionalContent: [
                "Welcome all employees",
                "Promote career mobility",
                "Emphasize kindness, community, collegiality, and accountability",
                "Support and sustain a sense of community across the District"
            ]
        }
    ];
      
      
    return (
        <div className="h-full">
            <SectionHeader title="LONG-TERM goals" backTo="Back" backToLink="/community-college-district" blueText={true}/>
            <div className="flex flex-col md:flex-row items-start mt-[21px] space-x-20">
                <Accordion items={items} />
            </div>
        </div>
    );
};

export default Goals;
