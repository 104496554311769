import React, { useState } from 'react';
import { useNavigate   } from 'react-router-dom';

const BentoBox = ({layout}) => {

	const [hoveredIndex, setHoveredIndex] = useState(null);
	let navigate = useNavigate();

    const totalRows = Math.max(...layout.map(item => item.row + item.rowSpan - 1));
    const totalCols = Math.max(...layout.map(item => item.col + item.colSpan - 1));

    const gridTemplateRows = `repeat(${totalRows}, auto)`;
    const gridTemplateColumns = `repeat(${totalCols}, 1fr)`;

	const redirect = (link) => {
		navigate(link, { replace: true });
	}

  	return (
		<>
			<div className="hidden lg:grid w-full overflow-hidden" style={{ gridTemplateRows, gridTemplateColumns }}>
				{layout.map((item, index) => (
					<div
						key={index}
						className={`${item.margin ? "ml-[13px]" : ""} rounded-xl relative pb-4 cursor-pointer`}
						style={{
							gridColumn: `${item.col + 1} / span ${item.colSpan}`,
							gridRow: `${item.row + 1} / span ${item.rowSpan}`,
							height: item.height
						}}
						tabIndex={0}
						onMouseEnter={() => setHoveredIndex(index)}
						onMouseLeave={() => setHoveredIndex(null)}
						onClick={() => redirect(item.link)}
						onKeyDown={(e) => {
							if (e.key === 'Enter') {
								redirect(item.link);
							}
						}}
					>
						
						<div className={`${hoveredIndex === index ? 'opacity-0' : 'opacity-60' } transition-filter duration-1000 absolute bottom-0 left-0 right-0 top-0 w-full overflow-hidden bg-[#008FD1] bg-fixed mb-4 rounded-[20px]`}></div>
						
						<img src={item.src} className="h-full w-full object-cover rounded-[20px]" alt="Item Background"></img>
						<div className="absolute inset-0 flex flex-col justify-center text-white p-8">
							<div className="flex items-center flex-col md:flex-row">
								{item.icon &&
									<img className={`w-[40px] h-[40px] transition-transform duration-1000 ${hoveredIndex === index ? 'scale-110' : ''}`} src={item.icon} alt="Icon" />
								}
								<div className="ml-0 md:ml-6">
									<h1 className="font-['Oswald'] font-bold md:text-[2rem] text-[1rem] xl:text-[2.5rem] uppercase">{item.title}</h1>
									{item?.description?.map((word, index) => (
										<p className="text-[0.5rem] md:text-[1.5rem] font-['IstokWeb'] font-normal whitespace-pre-line" key={index}>{word}</p>
									))}
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
			<div className="lg:hidden flex flex-col w-full overflow-hidden" style={{ gridTemplateRows, gridTemplateColumns }}>
				{layout.map((item, index) => (
					<div
						key={index}
						className={`rounded-xl relative pb-4 h-[371px] cursor-pointer`}
						onMouseEnter={() => setHoveredIndex(index)}
						onMouseLeave={() => setHoveredIndex(null)}
						onClick={() => redirect(item.link)}
					>
						
						<div className={`${hoveredIndex === index ? 'opacity-0' : 'opacity-60' } transition-filter duration-1000 absolute bottom-0 left-0 right-0 top-0 w-full overflow-hidden bg-[#008FD1] bg-fixed mb-4 rounded-[20px]`}></div>
						
						<img src={item.src} className="h-full w-full object-cover rounded-[20px]" alt="Item Background"></img>
						<div className="absolute inset-0 flex flex-col justify-center text-white p-8">
							<div className="flex items-center flex-col md:flex-row">
								{item.icon &&
									<img className={`w-[40px] h-[40px] transition-transform duration-1000 ${hoveredIndex === index ? 'scale-110' : ''}`} src={item.icon} alt="Icon" />
								}
								<div className="ml-0 md:ml-6">
									<h1 className="font-['Oswald'] font-bold text-[1.5rem] uppercase">{item.title}</h1>
									{item?.description?.map((word, index) => (
										<p className="text-[1rem] font-['IstokWeb'] font-normal whitespace-pre-line" key={index}>{word}</p>
									))}
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</>
  	);
};

export default BentoBox;
