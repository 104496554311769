import SectionHeader from "./SectionHeader";
import Accordion from "./Accordion";
import StudentIcon from "../assets/Icons/studentIcon.png";
import WelcomingIcon from "../assets/Icons/welcomingIcon.png";
import AgileIcon from "../assets/Icons/agileIcon.png";
import IdentityIcon from "../assets/Icons/identityIcon.png";
import ResourcefulIcon from "../assets/Icons/resourcefulIcon.png";

const GuidingPrinciples = ({ }) => {
    const items = [
        {
            title: "Student Centered",
            subtitle: "Design campuses from the student perspective",
            additionalTitle: "DISTRICTWIDE STRATEGIES:",
            additionalContent: [
                "Support the diverse needs of all students",
                "Consolidate related student support services",
                "Align campus organization to support career pathways",
                "Prioritize spaces for students to socialize, study, and collaborate (indoor and outdoor)"
            ],
            img: StudentIcon
        },
        {
            title: "Welcoming",
            subtitle: "Create welcoming and inviting campuses",
            additionalTitle: "DISTRICTWIDE STRATEGIES:",
            additionalContent: [
                "Develop a gateway arrival experience at each campus",
                "Create a welcome center at each campus",
                "Improve navigation and wayfinding for vehicles and pedestrians",
                "Design campuses that enhance belonging",
                "Make spaces for collaboration and connections the priority"
                
            ],
            img: WelcomingIcon
        },
        {
            title: "AGILE",
            subtitle: "Develop agile and responsive campuses",
            additionalTitle: "DISTRICTWIDE STRATEGIES:",
            additionalContent: [
                "Develop flexible spaces prepared for change​",
                "Maximize space utilization and limit single-use spaces​",
                "Develop indoor and outdoor spaces for multiple uses​",
                "Repurpose underperforming and underused facilities",
                "Position State Center to maximize state and local funding"
                
            ],
            img: AgileIcon
        },
        {
            title: "Identity",
            subtitle: "Enhance State Center Colleges' collegiate identity",
            additionalTitle: "DISTRICTWIDE STRATEGIES:",            
            additionalContent: [
                "Increase the visibility of the Colleges across the District",
                "Develop landscapes and buildings that encourage students to stay on campus",
                "Develop spaces to encourage student and faculty interactions",
                "Prioritize and feature Libraries/Learning Resource Centers",
                "Improve campus edges within surrounding neighborhoods"
            ],
            img: IdentityIcon

        },
        {
            title: "resourceful",
            subtitle: "Optimize physical resources across the District",
            additionalTitle: "DISTRICTWIDE STRATEGIES:",
            additionalContent: [
                "Align land use and facilities to support long-term goals",
                "Repurpose/replace/remove inefficient and outdated buildings",
                "Right-size buildings to meet program needs",
                "Create a culture of sustainability",
                "Leverage external funding and partnerships"
            ],
            img: ResourcefulIcon
        }
    ];
      
      
    return (
        <div className="h-full">
            <SectionHeader title="Facilities Guiding Principles" backTo="Back" backToLink="/community-college-district" blueText={true}/>
            <div className="flex flex-col md:flex-row items-start mt-[21px] space-x-20">
                <Accordion items={items} isPrinciples={true}/>
            </div>
        </div>
    );
};

export default GuidingPrinciples;
